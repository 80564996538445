import React, { useEffect, useContext } from 'react'
import { fetchEnLogin } from 'api/user'
import { providerContext } from 'components/Provider'

interface GoogleLoginProps {
  /**是否只在谷歌浏览器中加载 */
  onlyInGoogle?: boolean
}

const GoogleLogin: React.FC<GoogleLoginProps> = ({ onlyInGoogle = false }) => {
  const { user, isVerifyLogin } = useContext(providerContext)

  const clientId = '518291784352-ksedve4kd68114mdbs4sba9jc0i1l1t1.apps.googleusercontent.com'

  const loadGoogleJs = () => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    document.head.appendChild(script)
  }

  const handleCredentialResponse = async (response) => {
    const jwt = response.credential
    try {
      // 谷歌邮箱登录 不需要校验邮箱是否注册过，直接调登录接口，type传1
      const res = await fetchEnLogin({ jwt, type: 1 })

      const { result } = res.data
      // 如果返回了邮件证明没有注册
      if (result && result.email) {
        window.location.href = `/account/signup/?step=form2&email=${result.email}`
      } else {
        window.location.href = `/`
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (onlyInGoogle) {
      const isChrome = /Chrome/.test(navigator.userAgent)

      if (isChrome) {
        if (user === null && isVerifyLogin) {
          loadGoogleJs()
          window.onGoogleLibraryLoad = () => {
            window.google.accounts.id.initialize({
              client_id: clientId,
              callback: handleCredentialResponse,
            })
            window.google.accounts.id.prompt()
          }
        }
      }
    }
  }, [user, isVerifyLogin, onlyInGoogle])
  return <div id="g_id_onload" data-client_id={clientId} data-auto_prompt="false"></div>
}

export default GoogleLogin
