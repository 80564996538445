import React from 'react'
import * as style from './index.module.less'
import classnames from 'classnames'

const IntroductionCard = (props) => {
  const { icon, title, content, className = '' } = props
  return (
    <div className={classnames(style.introductionCard, className)}>
      <div>
        <img className="introduction-card-img" src={icon.publicURL} alt={title} />
      </div>
      <div className="introduction-card-title">{title}</div>
      <div className="introduction-card-content">{content}</div>
    </div>
  )
}

export default IntroductionCard
