import React, { useMemo, useState } from 'react'
import Button, { ButtonGroup } from 'components/Base/Button'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import TabCard from './TabCard'
import classnames from 'classnames'
import * as styles from './index.module.less'

export interface TabsContentProps {
  /**
   * 卡片左侧内容
   */
  left: {
    title: string
    mb_title: string
    icon: { publicURL: string } | string
    desc: string
    list: Array<string>
    buttons: Array<{ button_text: string; button_href: string; button_type: string }>
  }
  /**
   * 卡片右侧内容
   */
  right: {
    img: { publicURL: string } | string
  }
}

interface TabsProps {
  className?: string
  dataSource: {
    buttons: Array<{ button_text: string }>
    content: Array<TabsContentProps>
  }
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { className, dataSource } = props
  const { buttons, content } = dataSource
  const isMb = useIsWindowWidthSmaller()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  // 鼠标移入事件
  const handleMouseEnter = (index) => {
    return () => {
      setActiveIndex(index)
    }
  }
  // 选项卡激活内容
  const renderActiveContent = useMemo(() => {
    // 移动端
    if (isMb)
      return (
        <>
          {content?.map((child) => {
            return (
              <>
                <TabCard className="mt-[25px]" dataSource={child} />
                <div className="mt-[15px]">
                  {child.left.buttons.map(({ button_text, button_href, button_type }) => {
                    return (
                      <Button
                        className="mt-[10px]"
                        href={button_href}
                        btnType={button_type}
                        key={button_text + 'children'}
                      >
                        {button_text}
                      </Button>
                    )
                  })}
                </div>
              </>
            )
          })}
        </>
      )
    // pc端
    return <TabCard className="lg:mt-[60px]" dataSource={content[activeIndex]} />
  }, [activeIndex, isMb])
  return (
    <div className={classnames(styles.tabs, className)}>
      <ButtonGroup className={classnames(styles.buttons, 'lg:relative lg:flex  hidden lg:mt-[44px]')}>
        {buttons.map(({ button_text }, index) => {
          return (
            <Button
              btnType="black"
              className={classnames({ [styles['active']]: index === activeIndex })}
              onMouseEnter={handleMouseEnter(index)}
              key={button_text}
            >
              {button_text}
            </Button>
          )
        })}
      </ButtonGroup>
      {renderActiveContent}
    </div>
  )
}

export default Tabs
