import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
interface ImgCardProps {
  className?: string
  img: { publicURL: string } | string
  number: string
  title: string
}
const ImgCard: React.FC<ImgCardProps> = (props) => {
  const { className = '', img, title, number } = props
  return (
    <div className={classnames(styles.imgCard, className)}>
      <div className="en-img-card-photo">
        <img src={(img as { publicURL: string })?.publicURL || (img as string)} alt={title} />
      </div>
      <div className="en-img-card-number">{number}</div>
      <div className="en-img-card-title">{title}</div>
    </div>
  )
}
export default ImgCard
