import React, { useState, useRef, useEffect } from 'react'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import HomeBanner from 'components/EN/HomeBanner'
import NewBanner from 'components/EN/NewBanner'
import Section from 'components/EN/Section'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import ImgCard from 'components/EN/ImgCard'
import Tabs from 'components/EN/Tabs'
import IntroductionCard from 'components/EN/IntroductionCard'
import Button from 'components/Base/Button'
import GartnerCard from 'components/EN/GartnerCard'
import GrayButton from 'components/EN/GrayButton'
import GoogleLogin from 'components/GoogleLogin'
import VideoPlayer from 'components/VideoPlayer'
import SolutionTabs from 'components/EN/SolutionTabs'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import useZhichiChat from 'hooks/useZhichiChat'
import { Languages } from 'i18n/config'
import { graphql } from 'gatsby'
import classnames from 'classnames'
import * as styles from './en.module.less'

const Index = (props) => {
  const lang = Languages.EN
  const { data } = props
  const {
    homeEnYaml: {
      seo,
      banner,
      section_01,
      section_02,
      section_03,
      section_04,
      section_05,
      section_06,
      section_07,
      section_08,
    },
  } = data
  const { title, description, keywords } = seo
  const isMb = useIsWindowWidthSmaller()
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const curVideo = useRef<any>(null)
  const handleClickVideo = () => {
    setVideoUrl(banner.video_source)
    setTimeout(() => {
      curVideo?.current?.playVideo()
    }, 100)
  }
  const handlePlayVideo = (video_source) => {
    setVideoUrl(video_source)
    setTimeout(() => {
      curVideo?.current?.playVideo()
    }, 100)
  }
  useZhichiChat({ auto_expand: !isMb })

  return (
    <Layout>
      <Seo title={title} description={description} keywords={keywords} saTitle={title} lang={lang} />
      <main className={styles.root}>
        {/* banner */}
        <NewBanner clickVideo={handleClickVideo} {...banner} />
        {/* section 08 Boost Your Growth with Powerful Solutions*/}
        <Section
          title={section_08.title}
          className={classnames('lg:mt-[100px] mt-[60px] px-[2rem] lg:px-0', styles.section_08)}
        >
          <div className="section_08_desc">{section_08.desc}</div>
          <SolutionTabs dataSource={section_08.dataSource} handleClickVideo={handlePlayVideo} />
        </Section>
        {/* section 01  The trusted choice of many quality enterprises */}
        <Section className="lg:mt-[100px] mt-[60px] px-[2rem] lg:px-0">
          <div className={styles.sectionOneTitle}>{section_01.title}</div>
          <ScrollLogoWall className="lg:mt-[40px] mt-[10px] " {...section_01} />
        </Section>
        {/* section 02 Engage with your customer digitally  */}
        <Section className="lg:mt-[100px] mt-[44px] px-[2rem] lg:px-0" title={section_02.title}>
          <Tabs dataSource={section_02} />
        </Section>
        {/* section 03 */}
        <Section className="lg:mt-[100px] mt-[50px] px-[2rem] lg:py-[50px] bg-[#1F2D3D] py-[26px]">
          <div className="flex lg:w-[1135px] lg:justify-between flex-wrap">
            {section_03.map((item) => {
              return <ImgCard {...item} key={item.title} />
            })}
          </div>
        </Section>
        {/* section 04 Not Only Cloud But Also On-Premise */}
        <Section
          className={classnames(styles.section04, 'lg:mt-[100px] mt-[60px]  px-[2rem]')}
          title={section_04.title}
        >
          <div className="section04_desc">{section_04.desc}</div>
          <div className="lg:mt-[48px] text-center mt-[25px]">
            <Button btnType={section_04.button_type} href={section_04.button_href}>
              {section_04.button_text}
            </Button>
          </div>
        </Section>
        {/* section 05  End-to-End Customer Professional service */}
        <Section
          className={classnames(styles.section_05, 'lg:mt-[100px] px-[2rem] mt-[60px]')}
          title={section_05.title}
        >
          <div className="section05_desc">{section_05.desc}</div>
          <div className="section05_content">
            {section_05.dataSource.map((item) => {
              return <IntroductionCard {...item} key={item.title} />
            })}
          </div>
          <div className="lg:mt-[50px] mt-[3rem] text-center">
            <Button btnType={section_05.button_type} href={section_05.button_href}>
              {section_05.button_text}
            </Button>
          </div>
        </Section>
        {/* section 06 Gartner Peer Insight*/}
        <Section
          className={classnames(
            styles.section_06,
            'lg:mt-[100px] mt-[6rem] lg:pt-[100px] pt-[6rem]  bg-[rgba(4,203,148,0.1)] lg:pb-[85px] pb-[6rem]',
          )}
          title={section_06.title}
        >
          <GartnerCard {...section_06} className="lg:mt-[35px] mt-[14px]" />
        </Section>
        {/* section 07 Hundreds of Fortune 500 and Global 2000 Enterprises trust SensorsData */}
        {/* 暂时不用 */}
        {/* <Section
          className={classnames('lg:mt-[100px] mt-[6rem] mb-[5rem] lg:mb-[100px]  lg:whitespace-pre-wrap')}
          title={section_07.title}
        >
          <div className="lg:mt-[50px] lg:flex justify-between lg:w-[1200px]  hidden">
            {section_07.text_list.map((item) => {
              return <GrayButton text={item} key={item} />
            })}
          </div>
          <div className="lg:mt-[55px] mt-[2rem] px-[2rem] overflow-hidden">
            {section_07.logo_wall_list.map((item, index) => {
              return <ScrollLogoWall reverse={index === 1} logo_wall={item} title={section_07.title} key={index} />
            })}
          </div>
        </Section> */}
        <GoogleLogin onlyInGoogle />
        {videoUrl && (
          <VideoPlayer
            videoOpt={{
              sources: videoUrl,
            }}
            fuRef={curVideo}
            onClose={() => setVideoUrl(null)}
          />
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    homeEnYaml {
      seo {
        title
        description
        keywords
      }
      banner {
        title
        desc
        buttons {
          button_text
          button_href
          button_type
        }
        video_img {
          publicURL
        }
        video_source
      }
      section_01 {
        title
        logo_wall {
          publicURL
        }
      }
      section_02 {
        title
        buttons {
          button_text
        }
        content {
          left {
            title
            mb_title
            icon {
              publicURL
            }
            desc
            list
            buttons {
              button_text
              button_href
              button_type
            }
          }
          right {
            img {
              publicURL
            }
          }
        }
      }
      section_03 {
        img {
          publicURL
        }
        title
        number
      }
      section_04 {
        title
        desc
        button_type
        button_text
        button_href
      }
      section_05 {
        title
        desc
        dataSource {
          icon {
            publicURL
          }
          title
          content
        }
        button_text
        button_href
        button_type
      }
      section_06 {
        title
        content
        button_text
        button_type
        button_href
        widget_id
      }
      section_07 {
        title
      }
      section_08 {
        title
        desc
        dataSource {
          title
          desc
          buttons {
            button_text
            button_href
            button_source
          }
          img {
            publicURL
          }
          video_source
        }
      }
    }
  }
`

export default Index
