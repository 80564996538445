import React from 'react'

import classnames from 'classnames'
import * as styles from './index.module.less'
import Button, { ButtonGroup } from 'components/Base/Button'
import { TabsContentProps } from './index'

interface TabCardProps {
  className?: string
  dataSource: TabsContentProps
}
const TabCard: React.FC<TabCardProps> = (props) => {
  const { className = '', dataSource } = props
  const { left, right } = dataSource
  const { title, desc, icon, list, buttons, mb_title } = left
  return (
    <div className={classnames(styles.tabCard, className)}>
      <div className="tab-card-left">
        <div className="tab-card-left-title">
          <img
            className="tab-card-left-title-img"
            src={(icon as { publicURL?: string })?.publicURL || (icon as string)}
            alt={title}
          />
          <span className="tab-card-left-title-text">
            <span className="tab-card-left-title-theme">
              {mb_title}
              <br />
            </span>
            {title}
          </span>
        </div>
        <div className="tab-card-left-desc">{desc}</div>
        <hr className="tab-card-left-line" />
        <div className="tab-card-left-list">
          {list.map((item) => {
            return (
              <div className="tab-card-left-item" key={item}>
                {item}
              </div>
            )
          })}
        </div>
        <ButtonGroup className="tab-card-left-buttons">
          {buttons.map(({ button_text, button_href, button_type }) => {
            return (
              <Button btnType={button_type} href={button_href} key={button_text}>
                {button_text}
              </Button>
            )
          })}
        </ButtonGroup>
      </div>
      <div className="tab-card-right">
        <img
          className="tab-card-right-img"
          src={(right.img as { publicURL?: string })?.publicURL || (right.img as string)}
          alt={title}
        />
      </div>
    </div>
  )
}
export default TabCard
